<template>
  <v-dialog
    max-width="500"
    width="100%"
    :value="true"
    scrollable
    @keydown.esc="close"
  >
    <v-card>
      <v-progress-linear
        indeterminate
        color="error"
        v-if="loading"
      ></v-progress-linear>
      <v-card-title class="pa-0">
        <v-toolbar class="mb-2" color="error">
          <v-btn icon>
            <v-icon>mdi-timer</v-icon>
          </v-btn>
          <v-toolbar-title>{{ job.name }}</v-toolbar-title>
          <v-spacer />
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-checkbox v-model="job.active" label="active"></v-checkbox>
        <v-checkbox v-model="job.runNow" label="runNow"></v-checkbox>
        <v-checkbox v-model="job.interval" label="interval"></v-checkbox>
        <v-text-field
          type="number"
          v-model="job.intervalSeconds"
          label="intervalSeconds"
          v-if="job.interval"
        ></v-text-field>
        <TimeInput v-model="job.time" label="time" v-else />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="save" color="success"
          ><v-icon left>mdi-content-save</v-icon> Speichern</v-btn
        >
        <v-btn text @click="close"
          ><v-icon left>mdi-close</v-icon> Abbrechen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TimeInput from "common/components/TimeInput.vue";

export default {
  name: "JobDetails",
  components: { TimeInput },
  props: ["id"],
  data() {
    return {
      job: {},
      loading: true,
    };
  },
  methods: {
    close() {
      this.$router.back();
    },
    async save() {
      await this.apiPut({ resource: "job/job", data: this.job });
      this.$router.back();
    },
  },
  async created() {
    this.loading = true;
    this.job = await this.apiGet({ resource: "job/job", id: this.id });
    this.loading = false;
  },
};
</script>
